import { Link, useColorModeValue, Box, Tooltip } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

const NavLink = ({ children, ...props }) => {
    const location = useLocation();

    const bgAtivo = useColorModeValue('teal.700', 'gray.900');
    const bgInativo = useColorModeValue('teal.500', 'gray.600');

    const verificaAtivo = (caminho) => {
        if (caminho === '/') {
            return location.pathname === caminho ? bgAtivo : bgInativo;
        } else {
            return location.pathname.startsWith(caminho) ? bgAtivo : bgInativo;
        }
    };

    return (
        <Tooltip hasArrow label={props.tooltip}>
            <Link
                as={RouterLink}
                {...props}
                background={verificaAtivo(props.to)}
                color="white"
                w="100%"
                maxW={{ lg: '250px', xl: '250px', base: null }}
                height="50px"
                float="left"
                fontWeight="bold"
                fontSize="md"
                display="flex"
                alignItems="center"
                _hover={{
                    textDecoration: 'none',
                    fontSize: 'lg',
                }}
                justifyContent="center"
            >
                <Box textAlign="center">{children}</Box>
            </Link>
        </Tooltip>
    );
};

NavLink.propTypes = {
    to: PropTypes.string,
    children: PropTypes.node,
    tooltip: PropTypes.string,
};

export default NavLink;
