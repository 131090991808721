import { Spinner } from '@chakra-ui/spinner';

const Loading = () => (
    <Spinner
        thickness="4px"
        speed="1s"
        emptyColor="gray.200"
        color="blue.500"
        size="lg"
    />
);

export default Loading;
