import { IconButton, useColorModeValue, useColorMode } from '@chakra-ui/react';
import { MoonIcon, SunIcon } from '@chakra-ui/icons';

const ThemeSelector = (props) => {
    const { colorMode, toggleColorMode } = useColorMode();
    return (
        <IconButton
            icon={colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
            onClick={toggleColorMode}
            bg={useColorModeValue('teal.500', 'gray.600')}
            _hover={{
                backgroundColor: useColorModeValue('teal.700', 'gray.900'),
            }}
            color="white"
            //margin="0 auto"
            {...props}
        />
    );
};

export default ThemeSelector;
